import React from "react"
import styled, { css } from "styled-components"

const StyledButton = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1;
  background-color: transparent;
  box-sizing: border-box;
  flex-shrink: 0;
  text-decoration: none;
  display: inline-block;
  line-height: 2em;
  text-align: left;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  transition: all 0.5 ease-in-out;
  button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
  }
  button.button-class {
    width: 100%;
    height: auto;
  }
  button.button-class .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    max-width: 44px;
    height: 32px;
    background: #eb7c00;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 22px;
    display: flex;
    align-items: center;
  }
  button.button-class .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    bottom: 0;
    padding-left: 14px;
    padding-bottom: 2px;
    background: #fff;
  }
  button.button-class .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    line-height: 22px;
    background: none;
    img {
      width: 16px;
      height: 20px;
    }
  }
  button.button-class .circle .icon.arrow::before {
    position: absolute;
    right: 0.0025rem;
    width: 1.625rem;
    height: 0.625rem;
    line-height: 2rem;
    color: white;
    font-weight: bold;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  button.button-class .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    padding-right: 23px;
    margin: 0 0 0 42px;
    color: #eb7c00;
    color: transparent;
    white-space: nowrap;
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    @media ${({ theme }) => theme.breakpointDown.md} {
      font-size: 14px;
    }
  }
  button:hover .circle {
    max-width: 100%;
  }
  button:hover .circle .icon.arrow {
    background: transparent;
  }
  button:hover .button-text {
    color: #fff;
  }
  ${({ openMobile, theme }) =>
    openMobile
      ? css`
          @media ${({ theme }) => theme.breakpointDown.md} {
            button .button-text {
              color: white !important;
            }
            button .circle {
              max-width: 100% !important;
            }
          }
        `
      : ""}
  ${({ shadow, theme }) =>
    shadow
      ? css`
          .circle {
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.28);
          }
        `
      : ""}
`

const GettingHereButton = ({
  text,
  children,
  openMobile,
  shadow,
  ...props
}) => {
  return (
    <StyledButton
      shadow={shadow}
      openMobile={openMobile}
      content={children}
      {...props}
    >
      <button className="button-class">
        <span className="circle" aria-hidden="true">
          <span className="icon arrow">{children}</span>
          <span className="button-text">{text}</span>
        </span>
      </button>
    </StyledButton>
  )
}

export default GettingHereButton
