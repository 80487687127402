import React from "react"
import styled from "styled-components"

import address from "~images/address.png"
import GettingHere from "~images/getting_here.svg"
import telephone from "~images/telephone.png"
import url from "~images/url.png"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

import AnimatedButton from "../../components/AnimatedButton"
import GettingHereButton from "../../components/GettingHereButton"

const Wrapper = styled.div`
  width: 100%;
  margin: 50px 0px;
  @media (max-width: 1024px) {
    margin: 16px 0px;
  }
`

const LocationImg = styled.img`
  position: relative;
  object-fit: cover;
  object-position: center center;
  display: block;
  height: 167px;
  /* width: 90%; */
  @media ${({ theme }) => theme.breakpoint.sm} {
    height: 200px;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    max-height: 281px;
    height: auto;
    margin-bottom: 25px;
  }
  width: 100%;
  border-radius: 0 25px 0 25px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.26);
`

const LocationTitle = styled.div`
  margin-bottom: 8px;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
  color: #003d6a;
  margin-bottom: 16px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 18px;
    font-size: 28px;
    font-weight: 700;
    line-height: 20px;
  }
`

const AddressPhone = styled.div`
  color: #003d6a;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  padding-left: 30px;
  @media ${({ theme }) => theme.breakpoint.xl} {
    padding-left: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
`

const AddressIcon = styled.img`
  width: 14px;
  height: 21px;
  position: absolute;
  left: 15px;
  top: 3px;
  @media ${({ theme }) => theme.breakpoint.md} {
    top: 3px;
  }
  /* width: 24px; */
  /* height: 24px; */
  /* color: #ee751a; */
  margin-right: 8px;
`

const TelephoneIcon = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  left: 15px;
  margin-bottom: 0;
  /* width: 24px; */
  /* height: 24px; */
  top: 3px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 2px;
  }
  /* color: #ee751a; */
  margin-right: 8px;
`

const ParentWrapper = styled.div``
const SectionWrapper = styled(Container)`
  margin-top: 0;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 80px;
  }
`

const AddressWrapper = styled.div`
  /* padding-left: 30px; */
  svg {
    position: absolute;
    top: 0;
    left: 9px;
  }
`

const MB65 = styled.div`
  margin-bottom: 65px;
`

const OfficeWrapper = styled(Col)`
  &:nth-child(n + 2) {
    margin-top: 60px;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    &:nth-child(n + 2) {
      margin-top: auto;
    }
    &:nth-child(n + 3) {
      margin-top: 70px;
    }
  }
`

const TelephoneWrapper = styled(Col)`
  display: flex;
  margin-bottom: 20px;
  margin-top: 15px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 0px;
    margin-bottom: 20px;
  }
`
const WebsiteWrapper = styled(Col)`
  display: flex;
  margin-bottom: 20px;
  margin-top: 15px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const ImageSVG = styled.img`
  margin-bottom: 0;
  /* margin-left: 3px; */
  /* width: 120% !important; */
  /* margin-top: 5px; */
  position: relative;
  top: 2px;
`

const ContactLocations = ({ locations }) => {
  return (
    <SectionWrapper overflowDisabled>
      <MB65>
        <Wrapper>
          <Row>
            {locations &&
              locations.map(location => (
                <OfficeWrapper xs={12} sm={12} lg={6}>
                  <ParentWrapper>
                    <LocationImg
                      src={location.image.sourceUrl}
                      alt={location.image.alt}
                    />

                    <LocationTitle>{location.title}</LocationTitle>
                    <Row>
                      <Col xs={6} md={6} xl={6}>
                        <AddressWrapper>
                          <AddressIcon src={address} />

                          {/* <ContactSVG
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                              clip-rule="evenodd"
                            />
                          </ContactSVG> */}
                          <AddressPhone
                            dangerouslySetInnerHTML={{
                              __html: location.address,
                            }}
                          />
                        </AddressWrapper>
                      </Col>
                      <Col xs={12} md={6} xl={6}>
                        <Row>
                          <TelephoneWrapper>
                            <TelephoneIcon src={telephone} />
                            <AddressPhone>
                              <a
                                href={"tel:" + location.telephone}
                                target="_blank"
                                className="no-link"
                                rel="noreferrer"
                              >
                                {location.telephone}
                              </a>
                            </AddressPhone>
                          </TelephoneWrapper>
                          <Col>
                            <a
                              href={location.locationLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <GettingHereButton
                                text="Getting Here"
                                openMobile
                                shadow
                              >
                                <ImageSVG src={GettingHere} alt="Icon" />
                              </GettingHereButton>
                            </a>
                          </Col>
                          {location.webiste && (
                            <WebsiteWrapper>
                              <TelephoneIcon src={url} />
                              <AddressPhone>
                                <a
                                  href={location.webiste}
                                  target="_blank"
                                  className="no-link"
                                  rel="noreferrer"
                                >
                                  {location.webiste}
                                </a>
                              </AddressPhone>
                            </WebsiteWrapper>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </ParentWrapper>
                </OfficeWrapper>
              ))}
          </Row>
        </Wrapper>
      </MB65>
    </SectionWrapper>
  )
}

export default ContactLocations
